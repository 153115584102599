import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views-xz/main";

// views - home
import HomeUser from "../views-home/home-indx";
import HomeBusiness from "../views-home/home-bznx";

import NetworkList from "../views-network/network-list";

import CodeScanner from "../views-scanner/code-scanner";
import ScannerView from "../views-scanner/scanner-view";

import CheckinView from "../views-checkin/checkin-view";
import RegisterView from "../views-checkin/register-view";

// views - tickets
import TicketsView from "../views-tickets/tickets-view";

// views - auth
import AuthMailCode from "../views-auth/auth-mail-code";
import AuthMailFirebase from "../views-auth/auth-mail-firebase";
import AuthMailCheckFirebase from "../views-auth/auth-mail-check";
import AuthNext from "../views-auth/auth-next";
import AuthSessionX from "../views-auth/auth-session-x";


const routes = [

  { route:'/', content: <Main />, auth:false },

  { route:'/in/home', content: <HomeUser />, auth: false },
  { route:'/bz/home', content: <HomeBusiness />, auth: false },

  { route:'/bz/tickets/v/:id', content: <TicketsView />, auth: false },

  { route:'/in/network', content: <NetworkList />, auth: false },
  { route:'/bz/network', content: <NetworkList />, auth: false },
  
  { route:'/in/scan', content: <CodeScanner />, auth: false },
  { route:'/bz/scan', content: <CodeScanner />, auth: false },

  { route:'/in/checkin', content: <CheckinView />, auth: false },
  { route:'/bz/checkin', content: <CheckinView />, auth: false },
  
  { route:'/in/register', content: <RegisterView />, auth: false },
  { route:'/bz/register', content: <RegisterView />, auth: false },

  // auth
  { route:'/auth', content: <AuthMailCode />, auth: false },
  { route:'/auth/check', content: <AuthMailCheckFirebase />, auth: false },
  { route:'/auth/next', content: <AuthNext />, auth: false },
  { route:'/auth/x', content: <AuthSessionX />, auth: false },

]


export default function RouteX() {

  const { user } = useAuth();
  // console.log (user)

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}