// init
import axios from "axios";
const base = "https://ap-southeast-1.aws.data.mongodb-api.com/app/tokenize-alerts-intzu/endpoint"

// -----------------

export const CodeCreate = async (item) => {
  
  const basx = base + '/code/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }
  
}

export const CodeCheck = async (item) => {
  
  const basx = base + '/code/check';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }

}