// auth - session x
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//import { auth } from '../../services/firebase'
import { getAuth, signOut } from "firebase/auth";

import WebbLoader from "../webx/webb-loader";

import { AuthClearStore } from "../../services/srvc-auth-user";


const auth = getAuth();

export default function AuthSessionXModule () {

  const navigate = useNavigate();

  const [access, setAccess] = useState(true)

  AuthClearStore();
  setTimeout(() => {
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log ('signout: ', 'success')
      setAccess(false)

    }).catch((error) => {
      // An error happened.
      console.log ('signout: ', error.code)
      setAccess(false)
    });
  }, 2000);

  useEffect( () => {
    const fetchData = async() => {
      if (!access) navigate('/')
    }
    fetchData()
  },[access]);


  return (
  <>
    <WebbLoader />

  </>

  )
}