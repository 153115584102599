// contacts
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { TicketsList } from "../../services/srvc-tickets-realm";
import { CheckinStatusSetMail } from "../../services/srvc-checkin-realm";


// lists --------------->


// code --------------->

export default function CheckinViewModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const {id} = useParams();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(true);

  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);
  
  const [check, setCheck] = useState(false);
  const [user, setUser] = useState(false);

  const [list, setList] = useState([])
  const [data, setData] = useState({
    mail: '',
    ticket: ''
  })


  useEffect( () => {
    if (asset) {
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = await TicketsList({
          data: {user: asset.item},
          user: asset.item
        })
        console.log (result)
      
        setList(result.data.list)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);



  // useEffect for form validation
  // useEffect for form validation
  useEffect( () => {
    setCheck(false);
    if (data.mail!=='' && data.mail.includes('@') && data.mail.includes('.') && data.ticket!==''  )  
      setForm(true);
  },[data]);


  const handleSubmit = async () => {

    setSubmit(true)
    setLoading(true)

    const datx = { mail: data.mail, ticket: data.ticket }
    console.log ({data: datx, user: '******'})

    const result = await CheckinStatusSetMail({data: datx, user: '******'})
    console.log (result)
    if (result.data) {
      setDone(true)
    }
    else {setDone(false)}
    setLoading(false)

  };

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  // if (loading) return <WebbLoader/>

  return (
  <>
    {/* info */}
    <p className="text-bold">Check-in Guests with Email</p>

    {/* data */}
    <div className={submit ? '' : ''}>

      <WebbDividerSmall/>
      <div className="form-group mb-3">  
          <label className="form-label">Select Ticket <FormNeeded/></label>
          <select 
              className="form-select height-md border-none rounded-wd"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.ticket}
              disabled={loading}
              onChange={({ target }) => { console.log(target.value); handleChange("ticket", target.value)}}
            >
            <option value="">Select Ticket</option>
            {list && list.map((x, i) => (
              <option value={x.item} key={i}>{x.meta.name}</option>
            ))}
          </select>
        </div> 

      <div className="">
        <div className="form-group mb-3">  
          <label className="form-label">Email Address <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md border-none"
            value={data.mail}
            onChange={({ target }) => {handleChange("mail", target.value); }}
            disabled={loading}
            placeholder="user@mail.com">
          </input>
        </div>      

      </div>


      {/* action */}
      <WebbDividerSmall/>
      <div className={''}>
        <button className="btn btn-primary back-color-main rounded-wd border-none w-100"
          disabled={!form}
          onClick={() => handleSubmit()}
        >
          <span className="small">Submit</span>
        </button>
      </div>

      <div className={check && !user ? '' : 'd-none'}>
        <p className="m-0">Email is not registered</p>
        <p className="m-0">Checkin Not Possible</p>
      </div>

    </div>

    {/* results */}
    <div className={!loading && submit ? '' : 'd-none'}>
      <WebbDividerSmall />
      
      <div className={done? 'p-3 back-color-wite rounded-wd text-center ': 'd-none'}>
        <i className="bx bxs-check-circle text-icon-md text-color-success"></i>
        <p className="m-0">Success! Checkin Done</p>
      </div>

      <div className={done? 'd-none': 'p-3 back-color-wite rounded-wd text-center '}>
        Error! Email not registered
      </div>

    </div>


  </>

  )
}