// init
import axios from "axios";
const base = "https://ap-southeast-1.aws.data.mongodb-api.com/app/tokenize-user-souxo/endpoint"


// Function Calls

export const UserAccountList = async (item) => {

  const basx = base + '/users/accounts';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }

}

export const UserAccountCreate = async (item) => {

  const basx = base + '/users/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }

}


export const BusinessCreate = async (item) => {

  const basx = base + '/business/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }

}


export const UserNameSet = async (item) => {

}



export const GetUserNetworkList = async (item) => {

  const basx = base + '/network/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }

}

export const GetUserTeamList = async (item) => {

  const basx = base + '/business/team/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }

}