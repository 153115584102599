// main
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbFooter from "../content/webz/webb-footer-xw";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import CodeScannerModule from "../content/scanner/code-scanner";

export default function CodeScanner () {
  
  const meta = {
    name: 'Scan Code',
    memo: '',
    head: ''
  }

  return(

  <>
    <Helmet>
      <title>{meta.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <meta name="description" content={process.env.REACT_APP_WEBB_SITE_MEMO}/>
      <link rel="canonical" href="https://tokenize.ee" />
    </Helmet>

    <ContentFormat 
      
      name = {meta.name}
      header = {{ size: 'small', data: 
      <>
        <div className="">
          <WebbHeader data = {{name: meta.name, home: '/bz/home', link: '/bz/home'}}/>
        </div>
      </>
      }}

      media = {{ size: 'xtra', data: 
      <></>
      }}

      content = {{ size: 'small', data: 
      <>
        <WebbDividerSmall />
        <CodeScannerModule />
        


      </>
      }}
    
      footer = {{ size: 'small', data: 
      <></>
      }}
    
    ></ContentFormat>


  </>
  )
}