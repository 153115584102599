//media
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {QrScanner} from '@yudiel/react-qr-scanner';


import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { CheckinStatus, CheckinStatusSet } from "../../services/srvc-checkin-realm";

// lists --------------->
const eventx = '1e5d6a6e3ba84ddda6345edafef276fa8'


// code --------------->


export default function CodeScannerModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);
  
  const [ticket, setTicket] = useState('')
  const [check, setCheck] = useState(false);
  const [data, setData] = useState();

  const [user, setUser] = useState();

  useEffect( () => {
    if (ticket !=='') {
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = await CheckinStatus({
          data: {item: ticket},
          user: asset.item
        })
        console.log (result)
        setData(result.data)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[ticket]);


  const handleReset = async () => {
    setTicket('');
    setData()
  }


  const handleSubmit = async () => {

    setSubmit(true)
    setLoading(true)

    const datx = { item: ticket }
    console.log ({data: datx, user: '******'})

    const result = await CheckinStatusSet({data: datx, user: '******'})
    console.log (result)
    if (result.data) {
      setDone(true)
    }
    else {setDone(false)}
    setLoading(false)

  };

  if (loading) return <WebbLoader />

  return (
  <>

    {/* loader */}


    {/* data */}
    <div className={''} style={{backgroundColor:'#000000'}}>
      <QrScanner
        onDecode={(result) => {setTicket(result); console.log(result)}}
        onError={(error) => console.log(error?.message)}
        style={{ width: '100%', height: '150%' }}
      />
    </div>
    
    <WebbDividerMedium />
    <div className={''}>
      <p className="m-0 text-sm">ID: {ticket || '******'}</p>
    </div>
    
    <WebbDividerMedium />
    {/* action */}
    <div className="d-flex justify-content-between">
      <button className="btn btn-light back-color-wite border-none text-small w-50"
        onClick={ () => handleReset() }
      >Reset</button>

      <div className='mx-1'></div>

      <button className="btn btn-primary text-small border-none w-50"
        disabled={ticket==='' || loading || (data && data.checkin)}
        onClick={ () => handleSubmit() }
      >Check-In</button>

    </div>


    <WebbDividerSmall />
    <div className={'back-color-wite rounded-wd p-1 px-2'}>
      
      <WebbDividerSmall />
      <p className="m-0 text-bold text-sm">Number: {data && data.number || '******'}</p>

      <WebbDividerSmall />
      <p className="m-0 text-sm">Name: {data && data.user.name || '******'}</p>
      <p className="m-0 text-sm">Email: {data && data.user.mail || '******'}</p>
      
      <WebbDividerSmall />
      <p className="m-0">Ticket: {data && data.ticket.name || '******'}</p>
      <p className="m-0">Event: {data && data.event.name || '******'}</p>

      <WebbDividerSmall />
      <div className={data && data.checkin ? 'back-color-success rounded-md' : 'back-color-lite'}>
        <p className='text-center py-2 m-0 mb-1'>
          {data && data.checkin ? 'CHECKED-IN' : '***'}
        </p>
      </div>

    </div>

    <WebbDividerMedium />
    {/* results */}
    <div className={!loading && submit ? '' : 'd-none'}>
      <WebbDividerSmall />
      
      <div className={done? 'p-3 back-color-wite rounded-wd text-center': 'd-none'}>
        <i className="bx bxs-check-circle text-icon-md text-color-success"></i>
        <p className="m-0">Success! Checkin Done</p>
      </div>

      <div className={done? 'd-none': 'p-3 back-color-wite rounded-wd text-center '}>
        Error! Invalid Ticket or Ticket Number
      </div>

    </div>

    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
  
  </>
  )

    
}  