// web navigation
import { useEffect, useState } from "react";
import { Link, Route, useLocation } from "react-router-dom";

import { GetUserForm, ActiveSiteLink } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

const listNavs = require('../../data/navs-header-xw.json').data

export default function WebbFooter(props) {

  const usxx = GetUserForm();
  console.log (usxx)
  const asset = GetUserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  console.log(asset)

  const location = useLocation();  
  const linx = location.pathname.split('/')[2]
  const form = location.pathname.split('/')[1]

  const data = listNavs.filter(item => item.user.includes(asset.form) );


  return (
    <>
      
      <div className="d-md-none row row-cols-4 gx-1 back-color-wite fixed-bottom border-top">
      {data && data.map((item, i) => ( item.actv ?
        <div className="col text-center"  key={i} >
        <Link to={`/${form}/${item.link}`}className="text-center">
          {item.link === linx 
            ? <div className='text-color-dark p-1 back-color-next text-color-wite'>
                <i className={`${item.icon} text-icon-sm`}></i>
                <p className="small m-0">{item.name}</p>
              </div>
            : <div className='text-color-tone p-1 hirich'>
                <i className={`${item.icon} text-icon-sm`}></i>
                <p className="small m-0">{item.name}</p>
              </div>
          }
        </Link>
      </div>
      :''))}

    </div>
    
    </>
    )
}