// user
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { TicketsList } from "../../services/srvc-tickets-realm";
import WebbDividerSmall from "../webx/webb-divider-sm";

export default function TicketsListModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect( () => {
    if (asset) {
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = await TicketsList({
          data: {user: asset.item},
          user: asset.item
        })
        // console.log (result)
      
        setData(result.data.list)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  if (loading) return (<>Please Wait...</>)


  return (
  <>
    {/* info */}
    <p className="text-lead text-bold">Tickets</p>

    <div className="row row-cols-2 row-cols-md-3 g-3">
    {data && data.map((item, i) => (

      <div className="col mb-3" key={i}>
        
        <div className="cursor" onClick={() => navigate(`/${usxx}/tickets/v/${item.item}`)}>
          <div className="media-cube">
            <img className="rounded-wd" src={item.media.link} alt={item.meta.name}></img>
          </div>
        </div>

        
        <div className="mt-2">
          <p className="text-bold m-0">{item.meta.name}</p>
          <p className="text-small m-0">{item.event.name}</p>
        </div>

      </div>
    
    ))}
    </div>



  </>
  )
}
  