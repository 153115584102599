// auth - firebase mail link
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from '../../services/firebase'

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import WebbSpinner from "../webx/webb-spinner";
import FormNeeded from "../webx/form-needed";

import { SetAuthUser } from "../../services/srvc-auth-user";

import { CodeCreate, CodeCheck } from "../../services/srvc-code-realm";


export default function AuthMailCodeModule () {


  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);  
  
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(true);
  
  const [mail, setMail] = useState('')
  const [mailStatus, setMailStatus] = useState(false)

  const [code, setCode] = useState('')
  const [codeStatus, setCodeStatus] = useState(false)

  const [trxn, setTransaction] = useState('')


  const handleCodeCreate = async() => {
    setMailStatus(true);
    
    const datx = { user: mail, channel: "mail", memo: "auth: ticket checkin admin" }

    const result = await CodeCreate({data: datx, user: '******'})
    console.log (result)
    if (result.data) {
      setTransaction(result.data.trxn)
    }
    
  }


  const handleCodeCheck = async() => {
    setCodeStatus(true)

    const datx = { user: mail, code: code, trxn: trxn }
    console.log(datx)
    const result = await CodeCheck({data: datx, user: '******'})
    console.log (result)
    if (result.data) {
      SetAuthUser({user: mail})
      navigate('/auth/next')
      // navigate('/in/home')
    } else {
      setCodeStatus(false)
    }

  }


  const [data, setData] = useState()
  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }


  // if (loading && username) return <WebbSpinner/>


  return (
  <>
    {/* form */}
    <div className="">

      <div className="mb-3">  
        <label className="form-label small">Email Address <FormNeeded/></label>
        <input type="text" className="form-control height-md  "
          style={{fontSize:'0.9rem'}}
          value={mail}
          onChange={({ target }) => {setMail(target.value); }}
          disabled={loading || mailStatus}
          placeholder="">
        </input>
      </div>

      <div className="mb-3">  
        <label className="form-label small">One Time Passcode <FormNeeded/></label>
        <input type="text" className="form-control height-md  "
          style={{fontSize:'0.9rem'}}
          value={code}
          onChange={({ target }) => {setCode(target.value); }}
          disabled={loading || !mailStatus || codeStatus}
          placeholder="">
        </input>
      </div>

    </div>

    {/* memo */}
    <div className={mailStatus && code.length <= 0 ? 'text-color-tone' : 'd-none'}>
      OTP sent on email. Please check email (spam)
    </div>
    <div className={code.length > 0 ? 'text-color-tone' : 'd-none'}>
      Please enter 6 digit Passcode
    </div>


    {/* form */}
    <WebbDividerMedium />
    <div className={mailStatus ? 'd-none' : ''}>

      <button className="btn btn-primary w-100 text-small border-none"
        onClick={() => handleCodeCreate()}
        disabled={mail === '' || mail.length < 6 || !mail.includes('@') || !mail.includes('.')}
      >Get Passcode</button>

    </div>


    <div className={mailStatus ? '' : 'd-none'}>

      <button className="btn btn-primary w-100 text-small border-none"
        onClick={() => handleCodeCheck()}
      >Submit</button>

    </div>

  </>

  )
}