// user
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import WebbDividerSmall from "../webx/webb-divider-sm";

import { TicketsDetails } from "../../services/srvc-tickets-realm";
import { CheckinData } from "../../services/srvc-checkin-realm";
import WebbDividerMedium from "../webx/webb-divider-md";

const statisticsList = [
  {name: 'total', code:'total', count: 0, actv: true},
  {name: 'check-in', code:'checkin', count: 0, actv: true},
  
]


export default function TicketsDetailsModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  // console.log(asset)
  
  const {id} = useParams();
  console.log (id)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [stats, setStats] = useState([])

  useEffect( () => {
    if (asset) {
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = await TicketsDetails({
          data: {item: id}, user: asset.item
        })
        console.log (result)
        setData(result.data)

        const resultData = await CheckinData({
          data: {ticket: id}, user: asset.item
        })
        console.log (resultData)

        var statistics = [
          {...statisticsList[0], count: resultData.data.total},
          {...statisticsList[1], count: resultData.data.checkin},
          
        ]

        setStats(statistics)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  if (loading) return (<>Please Wait...</>)


  return (
  <>
    {/* info */}
    <div className="p-3 py-2 pe-2 back-color-wite rounded-wd">
    <div className="row g-2">
      <div className="col-9">
        <p className="text-normal text-bold m-0 mb-1">{data.meta.name}</p>
        <p className="m-0 mb-2 text-md">{data.meta.memo}</p>
        <p className="m-0 mb-2">Event: {data.event.name}</p>
      </div>      
      <div className="col-3">
        <div className="media-cube">
          <img src={data.media.link} className="rounded-wd" alt="..."></img>
        </div>
      </div>
      
    </div>
    </div>



    {/* data */}
    <WebbDividerSmall />
    <div className="row row-cols-2 g-1">
    {stats && stats.map((item, i) => (item.actv ?

      <div className="text-start" key={i}>

        <div className="back-color-wite p-3 py-2 mb-1 rounded-wd">
          <p className="text-lead m-0" style={{fontSize:'1.5rem'}}>{item.count || '***'}</p>
          <p className="text-small text-color-tone m-0">{item.name.toUpperCase()}</p>
        </div>

      </div>
    
    : ''))}
    </div>

  </>
  )
}
  