// user
import { useEffect, useState } from "react";

import WebbDividerMedium from "../webx/webb-divider-md";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

export default function UserInfocardModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();

  const [loading, setLoading] = useState(true)
  // const [data, setData] = useState()
    
  return (
    <>
      <div className="text-center">
        <h3 className="text-bold m-0">{asset ? asset.name : '******'}</h3>
        <p className="text-color-tone text-bold m-0 d-none">{asset ? asset.mail : '******'}</p>
      </div>


      {/* <div className="p-2 px-3 back-color-wite rounded-wd">

        <p className="m-0">{usxx === 'in' ? 'Personal' : 'Business'}</p>
      </div> */}

      <WebbDividerMedium/>
      
    </>
  );

}