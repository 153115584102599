// user
import { useEffect, useState } from "react";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { CheckinData } from "../../services/srvc-checkin-realm";


const statisticsList = [
  {name: 'total', code:'total', count: 0, actv: true},
  {name: 'day 1', code:'checka', count: 0, actv: true},
  
]


export default function UserStatisticsModule() {

  const usxx = GetUserForm();
  const asset = {form: 'ww'} // GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState( statisticsList )

  useEffect( () => {
    if (asset) {
      const fetchData = async() => {
        
        setLoading(true);
        
        const result = await CheckinData({
          data: {item: ''},
          user: asset.item
        })
        console.log (result)

        var statistics = [
          {...statisticsList[0], count: result.data.total},
          {...statisticsList[1], count: result.data.checkin},
          
        ]
      
        setData(statistics)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  return (
  <>

    <div className="row row-cols-3 g-1">
    {data && data.map((item, i) => (item.actv ?

      <div className="text-center" key={i}>

        <div className="back-color-wite p-3 py-2 mb-1 rounded-wd">
          <p className="text-bold m-0" style={{fontSize:'1.5rem'}}>{item.count || '***'}</p>
          <p className="text-small text-color-tone m-0">{item.name.toUpperCase()}</p>
        </div>

      </div>
    
    : ''))}
    </div>



  </>
  )
}
  